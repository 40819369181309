import path from 'path';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const info = function (...args: any[]): void {
  // eslint-disable-next-line no-console
  console.log(...args);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const error = function (...args: any[]): void {
  // eslint-disable-next-line no-console
  console.error(...args);
};

interface Logger {
  info(output: string): void;
  error(output: string | Error): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json(data: any): void;
}

/*
 * Creates a logger for the provided file.
 */
export const createLogger = (filename: string, appName?: string): Logger => {
  const prefix = typeof appName === 'string' ? appName : null;
  const basename = path.basename(filename, path.extname(filename));

  let namespace: string;
  if (prefix !== null) {
    namespace = `${appName}.${basename}`;
  } else {
    namespace = `${basename}`;
  }

  return {
    info(output: string) {
      info(`[${namespace}] ${output}`);
    },
    error(output: string | Error) {
      if (typeof output === 'string') {
        error(`[${namespace}] ${output}`);
      } else {
        error(`[${namespace}] error occurred:`);
        error(output);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    json(data: any) {
      info(`[${namespace}]: `);
      info(data);
    },
  };
};

